import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    login:true,
    datosPoli:{}
  },
  mutations: {
    Logueo(state,payload){
      state.login=payload
    },
    GuardaDato(state,payload){
      state.datosPoli=payload
      console.log("desde el store",state.datosPoli)
    },
  },
  actions: {
    logueo(context,payload){
      context.commit("Logueo",payload)
      },
      guardaDato(context,payload){
        context.commit("GuardaDato",payload)
        },
  },
  modules: {
  },
  getters:{
    getLogin(state){
      return state.login
    },
    getDatos(state){
      return state.datosPoli
    },
  }
})
